import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import UnderlayTitle from "../components/underlay-title";
import IframeWithSpinner from "../components/iframe-with-spinner";

export default function GiftVouchersPage() {
  const sections = {
    giftVouchers: {
      label: "Gift Vouchers",
      slug: "gift-vouchers",
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };

  return (
    <Layout title={"Gift Vouchers"}>
      <TopSection
        pageTitle={"Gift Vouchers"}
        sections={[sections.giftVouchers]}
      />
      <MidSection
        className={"bg-white"}
        slug={sections.giftVouchers.slug}
        prevSection={sections.top}
      >
        <Container className={"justify-center items-center"}>
          <div
            className={
              "flex px-4 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle
              overlay={true}
              size={"medium"}
              className={"z-0 text-center text-primary"}
            >
              Gift Vouchers
            </UnderlayTitle>

            <IframeWithSpinner
              src={"https://app.gift-it.com.au/buy/eddy+wolff"}
              loading={"lazy"}
              loadingMessage={"Loading Gift Voucher Form..."}
              title={"Gift Voucher Form"}
              className={"h-gift-vouchers w-full"}
              wrapperClassName={"w-full"}
            />
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}
